/**
 * /* todo: set font sizes and font family from body
 *
 * @format
 */

footer.footer {
  padding: 2rem 0;
}

.Footer_Upper {
  display: flex;
  align-items: center;
}

footer.footer div:first-of-type {
  padding: 1rem 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer.footer div:first-of-type .info-links a {
  margin: 0 1rem;
  text-decoration: none;
  color: #000;
}

.info-links a:hover {
  color: var(--secondary-color) !important;
}
footer.footer hr {
  height: 2px;
  background: #f6f8fe;
  border: none;
}

.btn-footer {
  display: inline-block !important;
}

.socials {
  display: flex;
  justify-content: space-between;
  width: 10%;
  margin: 2rem auto;
  align-items: baseline;
}

.socials > * {
  cursor: pointer;
}

footer.footer .copyright {
  text-align: center;
  font-size: small;
  font-weight: 200;
}

.footer_logo img {
  width: 4rem;
}

.footer_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register_hotel_link {
  font-weight: 600;
}

@media only screen and (max-width: 1008px) {
  .socials {
    width: 30%;
  }

  .info-links {
    font-size: 0.75rem;
  }

  .footer_logo img {
    width: 3rem;
  }

  footer.footer div:first-of-type .info-links a {
    margin: 0 0.5rem;
  }
}
