@use "./reset";
@use "./variables" as *;

body {
  font-family: $ff;
  padding: 0 2rem;
}

@media only screen and (max-width: 1008px) {
  body {
    padding: 0 0.5rem;
  }
}

.globalContainer {
  max-width: 1440px;
  margin: 0 auto;
}

.title {
  font-size: $fs-lg;
  color: $ttl-clr;
}

.description {
  font-size: $fs-md;
  color: $txt-clr2;
}

.btn {
  background: var(--secondary-color);
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0.75rem 1rem;
  font-weight: bold;
  font-size: 1rem;
}


