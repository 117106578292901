/** @format */

// typography

@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@600;900&display=swap");

:root {
  --font-family: "Outfit", sans-serif;
  --large-font-size: 1.25rem;
  --medium-font-size: 1rem;
  --small-font-size: 0.75rem;
}

@media (min-width: 968px) {
  :root {
    --large-font-size: 3rem;
    --medium-font-size: 1.25rem;
    --small-font-size: 0.75rem;
  }
}

$ff: var(--font-family);
$fs-lg: var(--large-font-size);
$fs-md: var(--medium-font-size);
$fs-sm: var(--small-font-size);

//colors

:root {
  --primary-color: #2d2278;
  --secondary-color: #e51a41;
  --title-color: #000;
  --text-color-one: #404040;
  --text-color-two: #8c8c8c;
}

$prm-clr: var(--primary-color);
$scn-clr: var(--secondary-color);
$ttl-clr: var(--title-color);
$txt-clr1: var(--text-color-one);
$txt-clr2: var(--text-color-two);
