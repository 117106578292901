.faq_question {
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.faq {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 2rem auto;
}

.faq_title_ {
  text-align: center;
  font-weight: bold;
  margin: 14rem 0 3.75rem 0 !important;
}

.faq_answer {
  width: 70%;
  margin: 0.5rem 0;
}

@media only screen and (max-width: 1008px) {
  .faq {
    width: 70%;
  }
  .faq_title_ {
    margin: 0rem 0 3.75rem 0 !important;
  }
}
@media only screen and (max-width: 500px) {

}


