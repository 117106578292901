/** @format */

.room_details_cont {
  width: 100%;
  border: 2px solid var(--primary-color);
  border-radius: 16px;
  display: flex;
  padding: 0.5rem;
  transition: 0.3s all ease-in;
}

.not_av {
  filter: grayscale(1);
  user-select: none;
  cursor: default !important;
}

.no_border {
  width: 100%;
  box-shadow: 0px 0px 15px 3px rgba(47, 36, 126, 0.15);
  border: 2px solid white;
  border-radius: 16px;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.3s all ease-in;
}

.room_type {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: bold;
}

.room_left {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.room_right {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-color);
  font-size: 0.8rem;
  font-weight: lighter;
  align-items: flex-end;

  .lower {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.room_feedback {
  color: #797777;
  font-size: 0.8rem;
  font-weight: lighter;
  margin: 0.5rem 0;
}

.show_more {
  color: var(--primary-color);
}
.pointer {
  cursor: pointer !important;
}
.not_pointer {
  cursor: default !important;
}
.room_right span {
  font-size: 2rem;
  font-weight: bold;
}

.room_right p {
  color: #797777;
  font-size: 0.8rem;
  font-weight: lighter;
  text-align: right;
}

.room_img {
  float: left;
  width: 12rem;
  height: 10.25rem;
  margin-right: 1rem;
  border-radius: 8px;
}

.HotelCart_HeartContainer {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  color: var(--secondary-color) !important;
}
.hotel_header_heart:hover {
  border: 1px solid var(--secondary-color);
  transition: all 0.3s ease-in;
}

.room_cart_discount {
  display: flex;
  flex-direction: column;
}
.room_cart_discount_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    padding: 0;
    margin: 0;
    font-size: 0.7rem;
    text-decoration: line-through;
    color: var(--text-color-two);
  }
}

.room_cart_price {
  align-items: baseline;
  display: flex;
  justify-content: flex-end;
}

.room_discount {
  margin-right: 0.3rem;
  background-color: var(--secondary-color);
  border-radius: 0.5rem;
  color: white;
  align-items: center;
  text-align: center;
  font-size: 0.7rem;
  padding: 0.2rem;
}

.room_cart_cont {
  display: flex;
  flex-wrap: wrap;

  .amenityImg {
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.2rem;
  }
}
@media only screen and (max-width: 500px) {
  .room_img {
    width: 5rem;
    height: 5rem;
    image-rendering: -webkit-optimize-contrast;
  }
}
