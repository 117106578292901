/** @format */

.email_container {
  width: 90%;
  background-color: #f6f8fe;
  margin: 10rem auto 5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email_title {
  text-align: center;
  font-weight: bold;
  width: 65%;
  margin: 3.75rem auto !important;
}

.email_input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 3.75rem;
}

.email_input input {
  width: 40%;
  padding: 0.75rem 1rem;
  font-size: 0.85rem;
  color: var(--primary-color);
  border: none;
  font-weight: lighter;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 0px 15px 3px rgba(47, 36, 126, 0.15);
  outline: none;
}

.emailResp {
  color: var(--secondary-color);
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.btn-2 {
  min-width: 10rem;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

@media only screen and(max-width: 1008px) {
  .email_input input {
    width: 60%;
  }
}
