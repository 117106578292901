.explore_pagination{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 8rem;
    .explore_pagination_click{
        cursor: pointer; 
        color:var(--primary-color);
    }
    .explore_pagination_click:hover{
        color:var(--secondary-color);
    }
    .explore_pagination_click_disabled{
        visibility: hidden;
    }
    .current_page{
        color: var(--secondary-color);
    }
}