*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: calc(1em + 0.5rem);
}

html,
html:focus-within {
  scroll-behavior: smooth;
}

html,
body,
#__next {
  height: 100%;
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s 0s;
}
input:-webkit-autofill::first-line,
input:-webkit-autofill:hover::first-line,
input:-webkit-autofill:focus::first-line,
textarea:-webkit-autofill::first-line,
textarea:-webkit-autofill:hover::first-line,
textarea:-webkit-autofill:focus::first-line,
select:-webkit-autofill::first-line,
select:-webkit-autofill:hover::first-line,
select:-webkit-autofill:focus::first-line {
  font-size: var(--medium-font-size);
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: var(--text-color);
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
  html,
  html:focus-within {
    scroll-behavior: auto;
  }
}
