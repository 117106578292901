.carousel__container {
  position: fixed;
  width: auto;
  margin: auto;
  padding: 0 5rem;
  z-index: 1;
  background-color: rgba(206, 206, 206, 0.85);
  top: 5rem;
}
.carousel-root .carousel-slider {
  width: 1066px !important;
  height: 600px !important;
}
