@media only Screen and (max-width: 1439px) {
  .Support_Container {
    margin: 0 3%;
  }
}

.contact_link {
  text-decoration: none;
  color: inherit;
}

.Support_Container {
  .cont-btn {
    width: 210px;
    height: 65px;
  }

  .add-mobile {
    display: none;
  }

  .Support_Img {
    padding-top: 15%;
  }

  .luxury_spa {
    display: none;
  }

  .Support_Bg {
    padding-top: 3.5rem;
    background: url("../../assets/images/street.webp") no-repeat center center;
    background-size: cover;
    transform: translateY(-5rem);
    z-index: -1;
    border-radius: 12px;
    height: 600px;

    .title {
      margin: 30px 0;
      font-size: 2rem;
    }
  }

  .Support_Welcome {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-left: 5%;
    padding-top: 5%;
  }

  .Support_Welcome p {
    line-height: 1.5;
    font-size: 20px;
    margin: 0 0 30px 0 !important;
    opacity: 0.5;
    color: #000;
    font-weight: 600;
  }

  .activeTag {
    font-weight: bold;
  }

  .inactiveTag {
    font-weight: lighter;
    opacity: 0.5;
  }

  .Support_Img {
    position: absolute;
    top: 0;
    right: 1rem;
  }

  .Support_Img img {
    width: 465px;
    height: 585px;
    border-radius: 1vw;
  }

  .TrendingHotels_Container {
    display: flex;
  }

  .TrendingHotels_Left {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 4%;
  }

  .TrendingHotels_Right {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-left: 4%;
    margin-top: 10%;
  }

  .trending_sub {
    display: flex;
    flex-direction: row;
  }

  .TrendingHotels_Right h1 {
    margin-top: 30%;
  }

  .TrendingHotels_Right p {
    margin: 7.5% 0;
  }

  .TrendingHotels_Right button {
    width: 175px;
  }

  .trending_container {
    width: 90%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
    margin: 7% auto;
    padding: 2.5rem;
    display: flex;
  }

  .trending_image img {
    height: 400px;
    width: 400px;
    border-radius: 1vw;
  }

  .trending_images {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 2rem;
  }

  .trending_images img {
    width: 125px;
    height: 125px;
    border-radius: 1vw;
  }

  .trending_type {
    color: var(--secondary-color);
    font-size: 2rem;
    font-weight: bold;
  }

  .trending_row {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }

  .trending_hotel {
    display: flex;
    flex-direction: column;
  }

  .trending_location {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: var(--text-color-two);
  }

  .trending_hotelname {
    font-size: 1.25rem;
    font-weight: bold;
  }

  .bookbtn {
    width: 150px;
  }

  .trending_desc {
    width: 40%;
  }

  .testimonials_container {
    margin-top: 10%;
  }

  .testimonials_container h1 {
    text-align: center;
  }

  .testimonial_carousel {
    width: 95%;
    margin: 5% auto;
    padding: 0.125rem;
  }

  .exploreBtn {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .Support_Img {
    display: none;
  }

  .cont-btn {
    width: 250px;
    margin: 0 auto;
    margin-top: 10px;
    height: 55px;
    font-size: 20px;
  }

  .Support_Bg {
    padding-bottom: 120px;
    background: url("../../assets/images/street.webp");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 6rem;

    .title {
      margin: 50px 0;
    }
  }

  .Support_Welcome {
    padding-top: 0;
    order: 2;
    width: 90%;
    margin-left: 0;
    text-align: center;
  }

  .luxury_spa {
    display: block;
    width: 90%;
  }

  .luxury_spa img {
    border-radius: 8px;
  }

  .exploreBtn {
    display: block;
    margin: 2rem auto 5rem auto;
  }

  .TrendingHotels_Container {
    flex-direction: column;

    .TrendingHotels_Left {
      order: 2;
      width: 90%;
      overflow-x: auto;
      display: -webkit-inline-box;
      flex-wrap: nowrap;
    }

    .TrendingHotels_Right {
      order: 1;
      width: 90%;
      text-align: center;
      margin-top: 0;

      button {
        display: none;
      }

      h1 {
        margin-top: 0;
      }
    }
  }

  .trending_sub {
    display: flex;
    flex-direction: column;
  }

  .trending_images {
    flex-direction: row;
    margin: 1rem 0;
  }

  .trending_container {
    flex-direction: column;
  }

  .trending_desc {
    width: 100%;
  }

  .trending_image img {
    width: 100%;
    height: auto;
    border-radius: 1vw;
  }

  .trending_images img {
    width: 30%;
    height: auto;
    border-radius: 1vw;
  }

  .trending_type {
    font-size: 1.25rem;
  }

  .trending_hotelname {
    font-size: 1rem;
  }

  .bookbtn {
    padding: 0.5rem;
    font-size: 0.75rem;
    width: auto;
  }

  .star-container > * {
    width: 12px !important;
    height: 12px !important;
  }

  .visited_container {
    padding: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .add-mobile {
    display: block;
  }

  .Support_Welcome p {
    font-size: 16px;
  }

  // .btn{
  //   width: 180px;
  //   height: 50px;
  //   // margin:20px auto 0 auto;
  //   font-size: 16px;
  //   border-radius: 14px;
  // }
}
