.filter__container{
  width: 30rem
}

.input-range__label--max,
.input-range__label--min{
  color: #c0cac8;
  font-size: 90%;
}

.input-range__track--background {
  background:#F3F1F0;
}

.input-range__track--active {
  background: var(--secondary-color);
}
.input-range__slider {
  background:white;;
  border-color: var(--secondary-color);
  border-radius:50%;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 40%;
}

.input-range__track {
  border-radius: 0.5vw;
  height: 0.4vw;
  
}

.input-range__label--value .input-range__label-container {
  border: 1px solid black;
  display: block;
  padding: 2px 5px 2px 5px;
  border-radius: 4px;
  background: #64716e;
  color: white;
  margin-top: -7px;
  font-size: 11px;
  display: none;
}

.explore_input1{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7%;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  margin-right: 3%;
}


.explore_input2{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  margin-left: 3%;
}


#input1, #input2{
  width:100%;
  font-size: 100%;
  color: var(--color-title);
  border-radius:5px;
  padding:3px;
  outline: none;
  

}

.budget_main_container{
  width: 100%;
display: flex;
align-items: baseline;
justify-content: space-around;
}

.budget_main_container input{
  text-align: center;
  border-radius: 0.5vw;
  border: 5px solid #F3F1F0;
}

.input-range__label--max, .input-range__label--min{
  display: none
}