.review_cont{
    width: 20rem;
    height: 10rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding:1rem;
    box-shadow: 0px 0px 16px 1px rgb(0 0 0 / 10%);
    margin-bottom: 5%;
    margin-right: 5rem;
    .header_cont{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .header_cont1{
            display: flex;
            align-items:center;
            .user_name{
                font-weight: 900;
                margin-right: 0.5rem;
                font-size: 1.2rem;
            }
            .number_rev{
            color:var(--secondary-color);
            font-size: 0.8rem;
            font-weight: 600;
            }
            
        }.went_to{
                font-size: 0.8rem;
                color: var(--text-color-two)
                
            }

       
    }

    .review_text{
        margin-top: 1rem;
        font-size: 0.8rem;

    }
}