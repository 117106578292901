/** @format */

.explore_main_container {
  padding-top: 5%;
  width: 86.5%;
  margin-bottom: 3rem;
}

@media only Screen and (max-width: 1440px) {
  .explore_main_container {
    margin-left: 8.5%;
  }
}

/* explore header */
.pin-icon {
  position: absolute;
  color: var(--secondary-color);
  cursor: pointer;
}
.pin-text {
  font-size: medium;
  font-style: normal;
  font-weight: bold;
  font-family: var(--font-family);
}
.explore_header_container {
  display: flex;
  height: 5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .explore_header_drop_container {
    display: flex;
  }
}
.dropdown_1 {
  width: 100%;
  height: 75%;
}

.css-11gbcj7-control {
  border: 5px solid #f3f1f0 !important;
  width: 100% !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-tj5bde-Svg {
  color: var(--secondary-color) !important;
}

.dropdown_container {
  width: 20rem;
  position: relative;
  margin-right: 1rem;
}
.css-319lph-ValueContainer {
  padding-left: 2rem !important;
}
.css-qc6sy-singleValue {
  font-size: 1rem;
  margin-left: 2rem !important;
}
.icon_dropdown {
  position: absolute;
  bottom: 35%;
  left: 8%;
  color: var(--secondary-color);
}

/*budget filter */
.header_budget {
  width: 15rem;
  height: 75%;
}

/*search */
.explore_search_container_phone {
  display: flex;
  width: 4rem;
  background-color: var(--secondary-color);
  color: white;
  height: 3rem;
  border-radius: 0.5vw;
  justify-content: center;
  align-items: center;
}
.explore_search_container_pc {
  cursor: pointer;
  display: flex;
  width: 4rem;
  background-color: var(--secondary-color);
  color: white;
  height: 3rem;
  border-radius: 0.5vw;
  justify-content: center;
  align-items: center;
}
.explore_search_container_phone {
  display: none;
}
.reset_container_pc,
.reset_container_phone {
  cursor: pointer;
  margin-left: 0.5rem;
  width: 4rem;
  display: flex;
  background-color: var(--secondary-color);
  color: white;
  font-size: 0.8rem;
  height: 3rem;
  padding: 0 0.5rem;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.reset_container_pc {
  border-radius: 0.5vw;
}
/* main content*/
.explore_2nd_Cont {
  margin-top: 2%;
  display: flex;
  width: 100%;
}

.explore_2_sub1 {
  width: 40rem;
}

.explore2_sub2 {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  width: 50%;
}
.map_parent {
  top: 8rem;
  position: sticky;
  width: 100%;
  height: 30rem;
}
.explore2_sub1_filter {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.filter_sort_list_container {
  box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.3);
  margin-top: 1rem;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  background-color: white;
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -2.3rem;
  .filter_sort_list_item {
    width: 100%;
    padding: 0.5rem;
    border-bottom: 1px solid var(--secondary-color);
    cursor: pointer;
    &:hover {
      background-color: var(--secondary-color);
      color: white;
    }
  }
  .filter_sort_list_item + .last {
    border-bottom: none;
  }
}
//  .off{
//   display: none;
// }
.filter_tags {
  background-color: #f1f0f3;
  width: fit-content;
  padding: 1% 3% 1% 3%;
  align-items: center;
  display: flex;
  margin: 5px;
  justify-content: center;
  border-radius: 0.5vw;
  font-size: 0.8rem;
  cursor: pointer;
  position: relative;
}
.tags_filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.filter_room_hotels {
  display: flex;
  width: 100px;
  border-radius: 0.5vw;
  font-size: 0.8rem;
  background-color: transparent;
  border: none;
}

.cont2_sub_info {
  display: flex;
}

.filter_button1 {
  padding: 3% 5% 3% 5%;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-bottom-left-radius: 0.5vw;
  border-top-left-radius: 0.5vw;
  font-size: 0.8rem;
  font-weight: 400;
}

.filter_button2 {
  padding: 3% 5% 3% 5%;
  background-color: #fff5f7;
  border: 2px solid #fccdd7;
  border-left: none;
  color: var(--secondary-color);
  border-bottom-right-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
  font-size: 0.8rem;
  font-weight: 400;
}
.active {
  background-color: #fff5f7;
  border: 2px solid #fccdd7;
  color: var(--secondary-color);
}

.filter_icon_sort {
  color: #fccdd7;
  cursor: pointer;
}
.filter_sort_container {
  z-index: 10;
  padding: 2px;
  position: relative;
  margin-right: 2rem;
}

.margin_top {
  margin-top: 0.3rem;
}
.filter_icon_sort:hover {
  color: var(--secondary-color);
}
//for keeping the list on screen
.filter_icon_sort:focus-within ~ .filter_sort_list_container {
  display: flex;
}
.filter_icon_sort:focus-within {
  color: var(--secondary-color);
}
.filter_sort_list_container:hover {
  display: flex;
}
.filter_sort_list_container {
  display: none;
}
.hide {
  display: none !important;
}
//

.filter_crown {
  position: absolute;
  right: 5%;
  top: -20%;
}

.active_btn {
  background-color: var(--secondary-color);
  color: white;
  border: none;
}

.not_active_btn {
  background-color: #fff5f7;
  color: var(--secondary-color);
  border: 2px solid #fccdd7;
}

/*detail container*/
.detail_container_resp {
  display: none !important;
}
.unavailable {
  filter: grayscale(1);
  user-select: none;
}

.sub_hotel_header_info {
  width: 90%;
}

.detail_container,
.detail_container_resp {
  margin-top: 1rem;
  background-color: white;
  border-radius: 1vw;
  width: 100%;
  min-height: 12rem;
  align-items: flex-start;
  box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 0.3rem;
  justify-content: space-around;
  margin-bottom: 1rem;
  padding: 1rem;

  .detail_sub_container1 {
    width: 30%;
    align-items: center;
    margin-top: 1.4rem;

    img {
      width: 100%;
      height: 8.5rem;
      border-radius: 1vw;
      cursor: pointer;
      object-fit: cover;
    }
  }

  .detail_sub_hotel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin-top: 1rem;

    .sub_hotel_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .sub_hotel_header_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .hotel_header_heart {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: white;
        color: var(--secondary-color);
      }

      .hotel_header_heart:hover {
        border: 1px solid var(--secondary-color);
        transition: all 0.3s ease-in;
      }
    }

    .sub_hotel_title {
      color: var(--title-color);
      font-size: 1.7rem;
      font-weight: 650;
      cursor: pointer;
    }
    .sub_hotel_location {
      display: flex;
      align-items: baseline;
      font-size: 0.8rem;
      cursor: pointer;
      color: var(--primary-color);
    }

    .sub_hotel_location:hover {
      color: var(--secondary-color);
    }
  }
  .sub_hotel_cont2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .sub_cont2_sub {
      color: var(--primary-color);
      border: 2px solid #edebff;
      border-radius: 0.4rem;
      display: flex;
      justify-content: space-between;
      padding: 0.2rem;
      font-size: 0.8rem;
      margin-bottom: 1rem;
      align-items: center;
    }
    .cont2_sub_price {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 0.1rem;
      font-size: 0.6rem;
      font-weight: 100;
      margin-right: 0.1rem;
      margin-top: 0.3rem;
    }
    i {
      margin-left: 0.5rem;
      display: flex;
      width: 1.4rem;
      height: 1.4rem;
      background-color: #edebff;
      align-items: center;
      justify-content: center;
      border-radius: 0.2rem;
      cursor: pointer;
    }
    i:hover {
      color: var(--secondary-color);
    }
    .cont2_footer {
      font-size: 0.7rem;
      display: flex;
      justify-content: flex-end;
      margin-right: 0.2rem;
      cursor: pointer;
    }

    .cont2_footer:hover {
      color: var(--secondary-color);
    }
  }

  .detail_sub_room {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    justify-content: space-around;
    .sub_cont2_container {
      color: var(--primary-color);
      border: 2px solid #edebff;
      border-radius: 0.4rem;
      padding: 0.5rem;
      font-size: 0.8rem;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
    }

    .sub_cont2_main_info {
      margin-right: 0.5rem;
    }
    .sub_room_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .room_header_heart {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: white;
        color: var(--secondary-color);
      }

      .room_header_heart:hover {
        border: 1px solid var(--secondary-color);
        transition: all 0.3s ease-in;
      }

      .sub_room_header_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .sub_room_title {
      color: var(--title-color);
      font-size: 1.5rem;
      font-weight: 650;
    }
    .sub_room_location {
      display: flex;
      align-items: baseline;
      font-size: 0.75rem;
      cursor: pointer;
      color: var(--primary-color);
    }
    .sub_room_location:hover {
      color: var(--secondary-color);
    }

    .sub_cont2_header {
      width: 100%;
      display: flex;
      .title {
        font-size: 0.7rem;
        color: var(--primary-color);
        font-weight: 600;
        margin-right: 0.5rem;
      }
      .subtitle {
        font-size: 0.7rem;
        color: var(--text-color-two);
      }
    }
    .sub_room_cont2 {
      width: 100%;
      .cont2_sub_avail {
        display: flex;
        width: 60%;
        margin-top: 0.5rem;

        .av_info {
          display: flex;
          align-items: baseline;
          font-size: 0.8rem;
          margin-right: 1rem;
        }
        img {
          width: 1.3rem;
          height: 1.3rem;
          margin-right: 0.3rem;
        }
      }
    }
    .amenties_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.5rem;
    }
    .amenityImg {
      height: 1.5rem;
      margin: 0.2rem;
    }
    .cont2_sub_room_price {
      font-size: 1rem;
      font-weight: bolder;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        margin-left: 0.1rem;
        font-size: 0.6rem;
        font-weight: 100;
        margin-right: 0.1rem;
        margin-top: 0.3rem;
      }
    }

    .cont2_room_price_offer {
      .room_offer_cont {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .room_offer {
        margin-right: 0.2rem;
        background-color: var(--secondary-color);
        border-radius: 0.5rem;
        color: white;
        align-items: center;
        text-align: center;
        font-size: 0.6rem;
        padding: 0.2rem;
      }
      p {
        padding: 0;
        margin: 0;
        font-size: 0.5rem;
        text-decoration: line-through;
        color: var(--text-color-two);
      }
    }
  }
  .cont2_room_footer {
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.2rem;
    margin-top: -5%;
    cursor: pointer;
  }

  .cont2_room_footer:hover {
    color: var(--secondary-color);
  }
}
@media only screen and (min-width: 768px) {
  .explore_header_container {
    display: flex;
    flex-direction: row !important;
    // align-items: baseline !important;
    justify-content: space-around !important;
    height: 4rem !important;

    .explore_header_drop_container {
      width: 45% !important;
    }
  }
  .filter__container {
    width: 45% !important;
  }

  .explore_filter_header_resp {
    display: none !important;
  }
  .filter_room_hotels {
    margin-top: 0.2rem;
  }

  .filter_icon_sort {
    margin-top: 0.7rem;
  }
}
@media only screen and (max-width: 1080px) {
  .explore2_sub2 {
    display: none;
  }

  .explore_2_sub1 {
    width: 100%;

    .detail_container {
      width: 100%;
    }
  }

  .hotel_details_container {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .explore_header_container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 8rem;
    .explore_header_drop_container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      width: 100%;

      .dropdown_container {
        margin-right: 0.4rem;
        width: 13rem;
      }

      .css-tj5bde-Svg {
        width: 0.8rem;
        height: 0.8rem;
      }

      .css-tlfecz-indicatorContainer {
        padding: 0 !important;
      }
      .css-319lph-ValueContainer {
        padding-left: 1.5rem !important;
        padding-right: 0.1rem !important;
      }
      .css-qc6sy-singleValue {
        font-size: 0.8rem;
      }
      .css-11gbcj7-control {
        border: 2px solid #f3f1f0 !important;
        border-radius: 10px;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
      }
    }

    .filter__container {
      width: 100%;
    }
    .budget_main_container {
      width: 100%;
      align-items: center;
      #input1,
      #input2 {
        font-size: 70%;
      }

      input {
        border: 2px solid #f3f1f0 !important;
      }
      .explore_input1,
      .explore_input2 {
        font-size: 1rem;
      }
      .input-range {
        margin: 0.2rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .explore_header_container {
    margin-top: 1rem !important;
  }
  .explore_2nd_Cont {
    .filter_tags {
      border-radius: 10px;
      .crown_icon {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    .filter_room_hotels,
    .filter_sort_container {
      display: none;
    }
  }

  .reset_container_phone {
    height: 2.3rem;
  }
  .reset_container_pc {
    display: none;
  }

  .explore_filter_header_resp {
    display: flex;
    justify-content: space-between;
    height: 2.3rem !important;
    align-items: center;
    .explore_resp_filter_main {
      display: flex;
      align-items: center;
      .sort_icon {
        width: 1.5rem !important;
        height: 1.5rem !important;
      }
    }
    .filter_room_hotels {
      margin-right: 1.5rem;
      .filter_button1 {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }

      .filter_button2 {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
  .explore_search_container_phone {
    cursor: pointer;
    display: flex;
    height: 2.3rem;
    border-radius: 10px;
    .search_icon {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
  .explore_search_container_pc {
    display: none;
  }
  .detail_container {
    display: none;
  }

  .detail_container_resp {
    width: 95%;
    margin-top: 1rem;
    background-color: white;
    border-radius: 10px;
    height: 12rem;
    box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.05);
    display: flex !important;
    flex-direction: column;
    padding: 0.2rem;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: relative;
    height: fit-content;

    .detail_sub_hotel {
      width: 100%;
    }
    .sub_hotel_header_info {
      width: 50%;

      .sub_hotel_title {
        font-size: 1.1rem;
      }

      .sub_hotel_location {
        font-size: 0.6rem;

        .location_icon {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
    .sub_hotel_header {
      display: flex;
      flex-direction: row !important;
      width: 100%;
      align-items: center;
    }

    .detail_sub_container1 {
      width: 40%;
      img {
        width: 10rem !important;
        height: 5rem;
        border-radius: 10px;
      }
    }

    .hotel_header_heart {
      position: absolute;
      right: 1%;
      top: -5%;
    }

    .detail_sub_room {
      width: 100%;

      .sub_cont2_header {
        .title {
          font-size: 0.6rem;
        }

        .subtitle {
          font-size: 0.6rem;
        }
      }
      .sub_cont2_container {
        justify-content: space-between;
      }
    }
    .sub_hotel_header_info {
      width: 45%;

      .sub_room_title {
        font-size: 1.1rem;
      }

      .sub_room_location {
        font-size: 0.6rem;

        .location_icon {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
    .sub_room_header {
      display: flex;
      flex-direction: row !important;
      width: 100%;
      align-items: center;
      margin-top: 1rem;
    }

    .detail_sub_container1 {
      width: 40%;
      img {
        width: 100% !important;
        height: 5rem;
        border-radius: 10px;
      object-fit: cover;

      }
    }

    .room_header_heart {
      position: absolute;
      right: 1%;
      top: -5%;
    }

    .cont2_room_footer {
      font-size: 0.6rem;
    }

    .sub_hotel_cont2 .cont2_footer {
      font-size: 0.6rem;
    }
  }
}
