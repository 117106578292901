.About_bg {
    padding-top: 3.5rem;
    background: url("../../assets/images/home-map.png") no-repeat center center;
    background-size: cover;
    transform: translateY(-5rem);
    z-index: -1;
    border-radius: 12px;
    height: auto;
    padding: 1rem;
    position: initial;
    display: flex;
    justify-content: space-between;

    .title {
      margin: 30px 0;
      font-size: 2rem;
    }

    .map_parent {
        margin-top: 8%;
        margin-right: 5%;
        position:initial ;
        width: 40%;
        height: 32rem;
        border-radius: 2rem;
      }
  }


  @media (max-width: 1200px) {
.About_bg{
    flex-direction: column-reverse;
    width: 100%;
    justify-content: space-around;
     .Support_Welcome{
        width: 100%;
        margin: 0;
    }

     .map_parent {
        margin-right: 0;
        width: 100%;
     }

    
}


  }