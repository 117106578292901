/** @format */

.hotel_container {
  margin: 0 5%;
  position: relative;
  .container_row {
    display: flex;
  }

  .leftpart {
    width: 60%;
  }

  .rightpart {
    width: 40%;
    margin: 2rem;
    margin-right: 0;
    box-shadow: 0px 0px 26px -5px rgba(47, 36, 126, 0.39);
    border-radius: 16px;
    position: sticky;
    height: 30rem;
    top: 5rem;
  }
  .details_header {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    .img {
      width: 5rem;
      margin-right: 1.5rem;
    }

    h1 {
      margin-right: 10px;
      font-size: 2rem;
    }

    .row1 {
      display: flex;
      align-items: center;
    }

    .row2 {
      display: flex;
      align-items: center;

      .rating {
        font-size: 0.9rem;
        margin: 0 0.75rem;
      }
      .location,
      .nbreviews {
        color: var(--text-color-two);
        font-size: 0.9rem;
      }

      .marker {
        margin-right: 0.3rem;
      }

      .nbreviews {
        margin-right: 1.5rem;
        margin-left: 1.5rem;
      }
    }
  }

  .details {
    p {
      color: var(--text-color-two);
      font-size: 0.9rem;
      width: 90%;
    }
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  .select_room {
    margin-top: 5rem;
  }

  .room_carts > * {
    margin: 1rem 0;
  }

  .promotion {
    position: relative;
    width: 100%;
    padding: 0.75rem 1.5rem;
    height: 10rem;
    border: none;
    border-radius: 16px;
    background: linear-gradient(to right, #7c69f2, #2f247e);

    .title {
      color: white;
      font-size: 1.25rem;
      margin: 0;
    }

    p {
      color: white;
      margin: 0.75rem 0;
    }

    button {
      position: absolute;
      background-color: white;
      color: var(--primary-color);
      border: none;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      bottom: 1rem;
      right: 1rem;
    }
  }

  .suggestions {
    margin-top: 3rem;
  }

  .rightpart {
    .subcontainer {
      padding: 1rem;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
      filter: invert(5%) sepia(76%) saturate(7472%) hue-rotate(252deg)
        brightness(103%) contrast(79%);
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(5%) sepia(76%) saturate(7472%) hue-rotate(252deg)
        brightness(103%) contrast(79%);
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input,
    .choose_date input {
      color: var(--primary-color);
      font-weight: bold;
      border: none;
      padding: 1rem 0.75rem;
    }

    select {
      border: 1px solid rgb(209, 200, 200);
      border-radius: 4px;
    }

    select,
    select option {
      color: var(--primary-color);
      font-weight: bold;
      padding: 1rem 0.75rem;
    }
    .separator {
      background-color: #f7faff;
      padding: 0.55rem 0.5rem;
      color: var(--primary-color);
    }

    input:nth-of-type(3) {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    input:nth-of-type(1) {
      border-radius: 8px;
      padding-left: 0.5rem !important;
      margin-right: 2rem;
    }

    input:nth-of-type(2) {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    // input:nth-of-type(2)::-webkit-calendar-picker-indicator {
    //   display: none;
    // }

    .price_details {
      background-color: #f7faff;
      padding: 1rem;
      border-radius: 8px;
      .row {
        display: flex;
        justify-content: space-between;
        div:nth-of-type(1) {
          color: black;
          font-size: 1rem;
        }
        div:nth-of-type(2) {
          color: var(--primary-color);
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }

    .totalPrice {
      margin: 1rem 0;
      .row {
        display: flex;
        justify-content: space-between;
      }

      div:nth-of-type(1) {
        color: var(--primary-color);
        font-size: 1.2rem;
        font-weight: bold;
      }
      div:nth-of-type(2) {
        color: var(--secondary-color);
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    .book_btn {
      width: 100%;
      border: none;
      border-radius: 8px;
      background-color: var(--primary-color);
      color: white;
      padding: 0.5rem 0;
      font-weight: normal;
    }

    .notes {
      background-color: #f7faff;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      margin-top: 0.5rem;

      span:nth-of-type(1) {
        color: var(--primary-color);
        font-size: 1.2rem;
        font-weight: bold;
      }
      span:nth-of-type(2) {
        color: var(--primary-color);
        font-size: 1rem;
      }
    }
  }

  .map_cont {
    max-width: 50rem;
    height: 30rem;
    display: flex;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .hotel_details_cont {
    display: block;
  }

  .hotel_details_mobile {
    display: none;
  }

  .location_container {
    display: flex;
    align-items: center;
  }

  .amenities {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 10rem;
  }
  .available {
    color: var(--primary-color);
  }
  .not_available {
    color: lightgray;
  }
  .hotel_amenities {
    display: flex;
    align-items: center;
  }

  .reviews {
    margin-top: 3rem;
  }

  .choose_time {
    width: 45%;
  }

  .choose_date {
    width: 45%;
    margin-right: 10px;
  }

  .amenity_icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
  }

  .amenity_icon_not_ac {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
    filter: grayscale(1);
    opacity: 0.5;
  }
  .bullet_not_active {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    margin-right: 1rem;
    background-color: #ff6e6e;
  }
  .bullet_active {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    margin-right: 1rem;
    background-color: #7cff90;
  }
}

.error {
  color: var(--secondary-color);
}

.unavialable_book {
  user-select: none;
  cursor: auto;
  background-color: var(--secondary-color) !important;
}
.confirm_booking {
  background-color: #5fce79 !important;
  transition: background-color 0.3s ease-in-out;
}

@media only screen and (max-width: 1008px) {
  .container_row {
    flex-direction: column;
  }

  .hotel_container .rightpart {
    width: 100%;
    margin: 0;
    order: 1;
    position: static;
    height: auto;
    width: auto;
  }

  .hotel_container .leftpart {
    width: 100%;
    order: 2;
  }

  .hotel_container .suggestions {
    display: -webkit-inline-box;
    overflow-x: scroll;
    width: 100%;
    flex-wrap: nowrap;
  }

  .hotel_details_cont {
    display: none !important;
  }

  .hotel_details_mobile {
    display: block !important;
    order: 0;
    margin-bottom: 2rem;
  }

  .row2 {
    flex-wrap: wrap;
  }

  .amenities {
    height: 20rem !important;
  }
}
@media only screen and (max-width: 500px) {
  .room_right span {
    font-size: 1.5rem !important;
  }

  .room_feedback {
    font-size: 0.6rem !important;
  }

  .promotion {
    font-size: 0.65rem;
  }
}

.modal {
  position: fixed;
  box-sizing: border-box;
  inset: 0px;
  width: 100vw;
  height: 100%;
  background-color: rgba(206, 206, 206, 0.85);
  font-size: large;
  padding: 2rem;
  line-height: 1.2;
  white-space: pre-wrap;
  overflow: auto;
  z-index: 0;
}

.gallery_carousel,
.room_carousel {
  width: 100%;
  margin: 5% auto;
  padding: 0.125rem;
}

.gallery_carousel img,
.room_carousel img {
  padding: 1rem;
}

// @media screen and (max-width: 450px) {
//   .choose_time {
//     width: 7rem;
//   }
// }

.choose_date label {
  color: var(--primary-color) !important;
  font-weight: bold;
  font-family: "Outfit", sans-serif;
}
