/** @format */

.testimonial_cart {
  width: 320px;
  height: 300px;
  border: none;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px 3px rgba(47, 36, 126, 0.15);
  margin: 1rem 0;
}

.testimonial_quote {
  align-self: flex-end;
}

.testimonial_name_cont {
  display: flex;
  flex-direction: column;
}

.testimonial_name {
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  .user_name {
    margin-right: 0.5rem;
  }
}

.testimonial_name span {
  color: var(--secondary-color);
  font-weight: 100;
  font-size: 0.75rem;
}

.testimonial_row1 {
  display: flex;
  align-items: flex-start;
  padding: 0 1.25rem;
}

.testimonial_row1 img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
}

.testimonial_went_to {
  font-size: 0.8rem;
  color: var(--text-color-two);
}

.testimonial_row2 {
  padding: 0 1.25rem;
  font-size: 0.85rem;
  margin: 1rem 0;
}

.testimonial_row3 {
  padding: 0 1.25rem;
}

.testimonial_amenities_title {
  font-size: 0.9rem;
  color: var(--primary-color);
  font-weight: 600;
}

.testimonial_amenities {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}

.amenity {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.25rem;
}

@media only screen and (max-width: 1008px) {
  .testimonial_cart {
    max-width: 40rem;
    height: 20rem;
  }

  .testimonial_cont {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
