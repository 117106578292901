.booking_cont {
  margin-top: 1rem;
  background-color: white;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1rem;
  position: relative;

  .detail_sub_container1 {
    width: 30%;
    align-items: center;

    img {
      width: 9.5rem;
      height: 8.5rem;
      border-radius: 1vw;
      object-fit: contain;
    }
  }

  .detail_sub_hotel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin-top: 1rem;

    .sub_hotel_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .sub_hotel_header_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .sub_hotel_title {
      color: var(--title-color);
      font-size: 1.7rem;
      font-weight: 650;
    }
    .sub_hotel_location {
      display: flex;
      align-items: baseline;
      font-size: 0.8rem;
      cursor: pointer;
      color: var(--primary-color);
    }

    .sub_hotel_location:hover {
      color: var(--secondary-color);
    }
  }
  .sub_hotel_cont2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .sub_cont2_sub {
      color: var(--primary-color);
      border: 2px solid #edebff;
      border-radius: 0.4rem;
      display: flex;
      justify-content: space-between;
      padding: 0.2rem;
      font-size: 0.8rem;
      margin-bottom: 1rem;
      align-items: center;
    }
    .cont2_sub_price {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 0.1rem;
      font-size: 0.6rem;
      font-weight: 100;
      margin-right: 0.1rem;
      margin-top: 0.3rem;
    }
    i {
      margin-left: 0.5rem;
      display: flex;
      width: 1.4rem;
      height: 1.4rem;
      background-color: #edebff;
      align-items: center;
      justify-content: center;
      border-radius: 0.2rem;
      cursor: pointer;
    }
    i:hover {
      color: var(--secondary-color);
    }
    .cont2_footer {
      font-size: 0.7rem;
      display: flex;
      justify-content: flex-end;
      margin-right: 0.2rem;
      margin-top: -0.5rem;
      cursor: pointer;
    }

    .cont2_footer:hover {
      color: var(--secondary-color);
    }
  }

  .detail_sub_room {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    justify-content: space-around;
    .sub_cont2_container {
      color: var(--primary-color);
      border: 2px solid #edebff;
      border-radius: 0.4rem;
      padding: 0.3rem;
      font-size: 0.8rem;
      margin-bottom: 1rem;
      display: flex;
    }

    .sub_cont2_main_info {
      margin-right: 0.5rem;
    }
    .sub_room_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .room_header_heart {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: white;
        color: var(--secondary-color);
      }

      .room_header_heart:hover {
        border: 1px dashed var(--secondary-color);
        transition: all 0.3s ease-in;
      }

      .sub_room_header_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .sub_room_title {
      color: var(--title-color);
      font-size: 1.5rem;
      font-weight: 650;
    }
    .sub_room_location {
      display: flex;
      align-items: baseline;
      font-size: 0.75rem;
      cursor: pointer;
      color: var(--primary-color);
    }
    .sub_room_location:hover {
      color: var(--secondary-color);
    }

    .sub_cont2_header {
      width: 100%;
      display: flex;
      .title {
        font-size: 0.7rem;
        color: var(--primary-color);
        font-weight: 600;
        margin-right: 0.5rem;
      }
      .subtitle {
        font-size: 0.7rem;
        color: var(--text-color-two);
      }
    }
    .sub_room_cont2 {
      width: 100%;
      .cont2_sub_avail {
        display: flex;
        width: 60%;
        margin-top: 0.5rem;

        .av_info {
          display: flex;
          align-items: baseline;
          font-size: 0.8rem;
          margin-right: 1rem;
        }
        img {
          width: 1.1rem;
          height: 1.1rem;
          margin-right: 0.3rem;
        }
      }
    }

    .cont2_sub_room_price {
      font-size: 1rem;
      font-weight: bolder;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        margin-left: 0.1rem;
        font-size: 0.6rem;
        font-weight: 100;
        margin-right: 0.1rem;
        margin-top: 0.3rem;
      }
    }

    .cont2_room_price_offer {
      .room_offer_cont {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .room_offer {
        margin-right: 0.2rem;
        background-color: var(--secondary-color);
        border-radius: 0.5rem;
        color: white;
        align-items: center;
        text-align: center;
        font-size: 0.6rem;
        padding: 0.2rem;
      }
      p {
        padding: 0;
        margin: 0;
        font-size: 0.5rem;
        text-decoration: line-through;
        color: var(--text-color-two);
      }
    }
  }
  .cont2_room_footer {
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.2rem;
    margin-top: -5%;
    cursor: pointer;
  }

  .cont2_room_footer:hover {
    color: var(--secondary-color);
  }

  .write_review {
    button {
      background: var(--primary-color);
      color: white;
      font-size: 1rem;
      font-weight: 500;
      border: none;
      border-radius: 0.4rem;
      padding: 0.25rem 0.5rem;
    }
  }

  .reviewed button {
    background-color: #5fce79 !important;
  }

  .review_modal {
    display: block;
    position: absolute; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    /* Modal Content/Box */
    .modal-content {
      background-color: #fefefe;
      margin: 0.25rem auto; /* 15% from the top and centered */
      padding: 20px;
      border: 1px solid #888;
      width: 50%;
      border-radius: 8px;
    }

    /* The Close Button */
    .close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }

    .close:hover,
    .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    textarea {
      width: 100%;
      padding: 0.25rem;
      border-radius: 8px;
    }

    button {
      margin-top: 0.25rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .disabledBtn {
      background: grey;
    }
  }
}

@media only screen and (max-width: 768px) {
  .booking_cont .detail_sub_hotel .sub_hotel_title {
    font-size: 1rem;
  }

  .booking_cont .detail_sub_container1 img {
    width: 5.5rem;
    height: 5.5rem;
  }

  .booking_cont .detail_sub_container1 {
    margin-right: 0.5rem;
  }

  .menu_body,
  .LgdOut_menu_body {
    padding: 1rem;
  }
}
