.Hotel_detail_container_resp {
  display: none !important;
}

.Hotel_detail_container,
.Hotel_detail_container_resp {
  margin-top: 1rem;
  background-color: white;
  border-radius: 1vw;
  width: 100%;
  min-height: 12rem;
  align-items: flex-start;
  box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 0.3rem;

  margin-bottom: 1rem;
  padding: 1rem;

  .detail_sub_container1 {
    width: 30%;
    align-items: center;
    margin-top: 1.4rem;

    img {
      width: 8.5rem;
      height: 8.5rem;
      border-radius: 1vw;
    }
  }

  .detail_sub_hotel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 73%;
    margin-top: 1rem;

    .sub_hotel_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .sub_hotel_header_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .hotel_header_heart {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: white;
        color: var(--secondary-color);
      }

      .hotel_header_heart:hover {
        border: 1px solid var(--secondary-color);
        transition: all 0.3s ease-in;
      }
    }

    .sub_hotel_title {
      color: var(--title-color);
      font-size: 1.7rem;
      font-weight: 650;
    }
    .sub_hotel_location {
      display: flex;
      align-items: baseline;
      font-size: 0.8rem;
      cursor: pointer;
      color: var(--primary-color);
    }

    .sub_hotel_location:hover {
      color: var(--secondary-color);
    }
  }
  .sub_hotel_cont2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .sub_cont2_sub {
      color: var(--primary-color);
      border: 2px solid #edebff;
      border-radius: 0.4rem;
      display: flex;
      justify-content: space-between;
      padding: 0.2rem;
      font-size: 0.8rem;
      margin-bottom: 1rem;
      align-items: center;
    }
    .cont2_sub_price {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 0.1rem;
      font-size: 0.6rem;
      font-weight: 100;
      margin-right: 0.1rem;
      margin-top: 0.3rem;
    }
    i {
      margin-left: 0.5rem;
      display: flex;
      width: 1.4rem;
      height: 1.4rem;
      background-color: #edebff;
      align-items: center;
      justify-content: center;
      border-radius: 0.2rem;
      cursor: pointer;
    }
    i:hover {
      color: var(--secondary-color);
    }
    .cont2_footer {
      font-size: 0.7rem;
      display: flex;
      justify-content: flex-end;
      margin-right: 0.2rem;
      margin-top: -5%;
      cursor: pointer;
    }

    .cont2_footer:hover {
      color: var(--secondary-color);
    }
  }

  .detail_sub_room {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    justify-content: space-around;
    .sub_cont2_container {
      color: var(--primary-color);
      border: 2px solid #edebff;
      border-radius: 0.4rem;
      padding: 0.5rem;
      font-size: 0.8rem;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
    }

    .sub_cont2_main_info {
      margin-right: 0.5rem;
    }
    .sub_room_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .room_header_heart {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: white;
        color: var(--secondary-color);
      }

      .room_header_heart:hover {
        border: 1px solid var(--secondary-color);
        transition: all 0.3s ease-in;
      }

      .sub_room_header_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .sub_room_title {
      color: var(--title-color);
      font-size: 1.5rem;
      font-weight: 650;
    }
    .sub_room_location {
      display: flex;
      align-items: baseline;
      font-size: 0.75rem;
      cursor: pointer;
      color: var(--primary-color);
    }
    .sub_room_location:hover {
      color: var(--secondary-color);
    }

    .sub_cont2_header {
      width: 100%;
      display: flex;
      .title {
        font-size: 0.7rem;
        color: var(--primary-color);
        font-weight: 600;
        margin-right: 0.5rem;
      }
      .subtitle {
        font-size: 0.7rem;
        color: var(--text-color-two);
      }
    }
    .sub_room_cont2 {
      width: 100%;
      .cont2_sub_avail {
        display: flex;
        width: 60%;
        margin-top: 0.5rem;

        .av_info {
          display: flex;
          align-items: baseline;
          font-size: 0.8rem;
          margin-right: 1rem;
        }
        img {
          width: 1.1rem;
          height: 1.1rem;
          margin-right: 0.3rem;
        }
      }
    }
    .amenties_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.5rem;
    }
    .amenityImg {
      height: 2rem;
      width: 1.5rem;
      margin-right: 0.5rem;
    }
    .cont2_sub_room_price {
      font-size: 1rem;
      font-weight: bolder;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        margin-left: 0.1rem;
        font-size: 0.6rem;
        font-weight: 100;
        margin-right: 0.1rem;
        margin-top: 0.3rem;
      }
    }

    .cont2_room_price_offer {
      .room_offer_cont {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .room_offer {
        margin-right: 0.2rem;
        background-color: var(--secondary-color);
        border-radius: 0.5rem;
        color: white;
        align-items: center;
        text-align: center;
        font-size: 0.6rem;
        padding: 0.2rem;
      }
      p {
        padding: 0;
        margin: 0;
        font-size: 0.5rem;
        text-decoration: line-through;
        color: var(--text-color-two);
      }
    }
  }
  .cont2_room_footer {
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.2rem;
    margin-top: -5%;
    cursor: pointer;
  }

  .cont2_room_footer:hover {
    color: var(--secondary-color);
  }
}

@media only screen and (max-width: 767px) {
  .Hotel_detail_container {
    display: none;
  }

  .Hotel_detail_container_resp {
    width: 95%;
    margin-top: 1rem;
    background-color: white;
    border-radius: 10px;
    height: 12rem;
    box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.05);
    display: flex !important;
    flex-direction: column;
    padding: 0.2rem;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: relative;
    height: fit-content;

    .detail_sub_hotel {
      width: 100%;
    }
    .sub_hotel_header_info {
      width: 55%;

      .sub_hotel_title {
        font-size: 1.1rem;
      }

      .sub_hotel_location {
        font-size: 0.6rem;

        .location_icon {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
    .sub_hotel_header {
      display: flex;
      flex-direction: row !important;
      width: 100%;
      align-items: center;
    }

    .detail_sub_container1 {
      width: 40%;
      img {
        width: 10rem !important;
        height: 5rem;
        border-radius: 10px;
      }
    }

    .hotel_header_heart {
      position: absolute;
      right: 1%;
      top: -5%;
    }

    .detail_sub_room {
      width: 100%;

      .sub_cont2_header {
        .title {
          font-size: 0.6rem;
        }

        .subtitle {
          font-size: 0.6rem;
        }
      }
      .sub_cont2_container {
        justify-content: space-between;
      }
    }
    .sub_hotel_header_info {
      width: 55%;

      .sub_room_title {
        font-size: 1.1rem;
      }

      .sub_room_location {
        font-size: 0.6rem;

        .location_icon {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
    .sub_room_header {
      display: flex;
      flex-direction: row !important;
      width: 100%;
      align-items: center;
      margin-top: 1rem;
    }

    .detail_sub_container1 {
      width: 40%;
      img {
        width: 10rem !important;
        height: 5rem;
        border-radius: 10px;
      }
    }

    .room_header_heart {
      position: absolute;
      right: 1%;
      top: -5%;
    }

    .cont2_room_footer {
      font-size: 0.6rem;
    }

    .sub_hotel_cont2 .cont2_footer {
      font-size: 0.6rem;
    }
  }
}
