/** @format */

.HotelCart_Container {
  width: 250px;
  height: 325px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.725rem;
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 5%;
}

.HotelCart_Img {
  width: 225px;
  height: 175px;
  image-rendering: -webkit-optimize-contrast;
  cursor: pointer;
  border-radius: 8px;
}

.HotelCart_Name {
  font-size: 22px;
  font-weight: 600;
  margin: 0.5rem 0;
  align-self: baseline;
}

.HotelCart_Location {
  display: flex;
  align-items: center;
  align-self: baseline;
}

.HotelCart_RatingRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.HotelCart_HeartContainer {
  width: 32px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.HotelCart_HeartContainer {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  color: var(--secondary-color);
}

.HotelCart_HeartContainer:hover {
  border: 1px solid var(--secondary-color);
  transition: all 0.3s ease-in;
}

@media only screen and (max-width: 1200px) {
  .HotelCart_Container {
    margin: 0.5rem 1rem;
  }
}
