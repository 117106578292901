/** @format */

@media only Screen and (max-width: 1439px) {
  .header_container {
    margin: 1% 5%;
  }
  .MenuActive {
    display: block;
  }
}

.header_container {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.header_img_container img {
  width: 4rem;
  cursor: pointer;
}

.header_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 5%;
}

.header_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
}

.header_link {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  transition: 0.2s color ease-in-out;
}

.header_link:hover {
  color: var(--secondary-color);
}

.header_button {
  background: none;
  border-radius: 5px;
  border: 3px solid var(--secondary-color);
  color: var(--secondary-color);
  width: 125px;
  font-weight: bold;
  font-size: 14px;
  padding: 0.125rem 0;
}

.ham {
  display: none;
}
.ham__Button {
  border: none;
}

.Menu,
.MenuActive {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.2);
}

.MenuActive {
  opacity: 1;
  visibility: visible;
}

.SubMenuActive {
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-left: auto;
  width: 50%;
  // min-height: 35rem;
  min-height: 100%;
}

.menu_head {
  position: relative;
  img {
    width: 100%;
  }
  button {
    position: absolute;
    top: 5rem;
    right: 2rem;
    border: none;
    padding: 0.5rem 0.75rem;
    border-radius: 8px;
    background: white;
    font-size: 2rem;
    font-weight: bold;
    color: var(--secondary-color);
  }
}

.MuiButton-root {
  min-width: auto !important;
}

.menu_body,
.LgdOut_menu_body {
  padding: 2rem;
  padding-top: 0;
  height: max-content;

  .img-container {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(206, 206, 206, 0.748);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    img,
    .file_input {
      border-radius: 50%;
      width: 50px !important;
      height: 50px !important;
    }

    .user_info {
      display: flex;
      flex-direction: column;
      margin-left: 1.5rem;

      .user_name {
        font-weight: bold;
        display: flex;
        align-items: center;

        div:first-of-type {
          margin-right: 0.5rem;
        }
      }

      .edit {
        cursor: pointer;
      }

      .user_email {
        font-size: 0.75rem;
        color: var(--text-color-two);
      }
    }
  }

  .tab {
    padding: 0.5rem 1rem;
    font-weight: bold;
    background-color: #f6f9fe;
    width: 100%;
    font-size: 0.85rem;
    margin: 0.75rem 0;
    cursor: pointer;
    display: flex;

    svg {
      margin-right: 0.5rem;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    .changePassword {
      color: var(--text-color-two);
      font-size: 0.85rem;
      cursor: pointer;
    }

    .logout {
      color: var(--secondary-color);
      font-weight: bold;
      cursor: pointer;
    }

    span {
      color: var(--primary-color);
      margin: 0 1rem;
      cursor: pointer;
    }
  }
}

.disabled {
  color: grey !important;
  cursor: default !important;
}

@media only screen and (max-width: 1008px) {
  .ham {
    display: block;
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  .MenuActive {
    display: block;
  }

  .SubMenuActive {
    width: 75%;
  }

  .header_links {
    display: none;
  }
  .LoggedOut {
    list-style-type: none;
    margin-bottom: 2rem;
  }
  .LoggedOut_li {
    font-weight: 900;
    font-size: 1.2rem;
    cursor: pointer;
    margin-bottom: 2rem;
    width: fit-content;
  }
  .LoggedOut_li:hover {
    color: var(--secondary-color);
  }

  .LgdOut_Btns {
    display: flex;
    flex-direction: column;
  }
  .LgdOut_login_btn,
  .LgdOut_logout_btn {
    background: none;
    border: none;
    padding: 0.2rem;
    border-radius: 0.5rem;
    border-width: 0.3rem;
    font-weight: 900;
  }
  .LgdOut_login_btn {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    margin: 1rem 0 1rem 0;
    border: solid;
  }
  .LgdOut_logout_btn {
    color: white;
    background-color: var(--secondary-color);
    padding: 0.3rem;
  }
  .LoggedOut_Header_hr {
    border-top: solid 0.2rem #2d2278;
    border-radius: 1rem;
  }
}

.Header__Arrow {
  // transform: rotate(0deg);
  transition: all 0.2s ease-in;
  margin: 0 auto;
}

.tab .open {
  transform: rotate(180deg);
}

.content_hide {
  max-height: 0;
  display: none;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.content-show {
  max-height: 500px;
  transition: max-height 1s ease-in;
}

.LoggedIn {
  display: none;
}

@media only screen and (max-width: 1008px) {
  .LoggedIn {
    display: block;
    list-style-type: none;
  }
}

.tab_content_container {
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.clicked {
  max-height: 500px;
  transition: max-height 0.5s ease-in-out;
  overflow-y: overlay;
}

.user_info_header {
  display: flex;
  justify-content: space-evenly;
}
.user_info_header input {
  width: 9rem;
  margin-right: 0.5rem;
}
.user_info_header input::placeholder {
  color: rgba(138, 137, 137, 0.478);
}

.fa-check-btn {
  color: green;
  margin: auto;
}
.file_input {
  padding: 0 !important;
  background-color: rgba(206, 206, 206, 0.562) !important;
}

.hide {
  z-index: -1;
}
.img_container {
  padding: 0;
  width: fit-content;
  height: fit-content;
  position: relative;
}

.notification_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .title {
    font-size: 1rem;
  }

  .activeNotification {
    font-weight: bold;
    color: var(--primary-color);
  }

  .description {
    font-size: 0.75rem;
  }

  .date {
    display: flex;
    justify-content: flex-end;
  }
}
