.preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;

  div:first-of-type {
    display: flex;
  }

  div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 4.5rem;

    .type {
      color: var(--primary-color);
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .price_col {
    display: flex;
    align-items: baseline;
    color: var(--primary-color);
    font-size: 0.7rem;
    span {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  .changeRoom {
    text-align: right;
    font-size: 0.7rem;
    color: var(--primary-color);
  }

  .prev_img {
    border-radius: 8px;
  }
}
