/** @format */

.confirm_container {
  display: flex;
  padding-left: 5%;
}

.confirm_left,
.confirm_right {
  display: flex;
  flex-direction: column;
}

.ConfirmRoomPreview {
  padding: 1rem;
  display: flex;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  .left,
  .right {
    display: flex;
    flex-direction: column;
  }

  .left {
    width: 70%;
    .room_type {
      font-size: 1rem;
      font-weight: 600;
      color: var(--primary-color);
    }

    .room_description {
      color: grey;
      font-size: 0.75rem;
      margin: 1rem 0;
    }

    .room_amenities {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .amenityImg {
        height: 1.5rem;
        width: 2rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }
    }
  }

  .right {
    width: 30%;
    text-align: right;
    justify-content: flex-end;

    .price span {
      font-size: 2rem;
      color: var(--primary-color);
      font-weight: 700;
    }
  }
}

.confirm_left {
  width: 55%;
  padding: 2rem;
  box-shadow: 0px 0px 15px 3px rgba(47, 36, 126, 0.15);
  border-radius: 8px;

  .mobile_text {
    display: none;
  }
}

.confirm_right {
  width: 45%;
  padding: 2rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.confirm_status_text {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}

.confirm_status img {
  float: left;
  margin-right: 1rem;
}

.confirm_status {
  background-color: #f7faff;
  padding: 1rem 1.5rem;
  border-radius: 8px;
}

.confirm_status_text h1 {
  font-size: 2rem;
}

.confirm_status_text span {
  font-size: 1rem;
  color: #797777;
}

.steps_path {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

.steps_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.booking_steps {
  display: flex;
  margin: 2rem 0;
}

.right_row {
  display: flex;
  justify-content: space-between;
}

.right_row_title,
.right_row_nb {
  font-size: 2rem;
  font-weight: 500 !important;
}

.right_row_nb span {
  display: none;
}

.right_row_location {
  display: flex;
  align-items: center;
  color: #b2b2b2;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.booking_nb_text {
  color: #b2b2b2;
  font-size: 0.75rem;
}

.hotel_img {
  width: 60rem;
  height: 22.5rem;
  border-radius: 8px;
  margin-top: 2rem;
}

.confirm_help {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.confirm_help button {
  margin-left: 10px;
}

.confirm_help span {
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
}

.cancel_modal {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
}

.cancel_cont {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #2f247e40;
  margin: 12.5rem auto 0 auto;
  padding: 1.25rem;
  width: 25rem;
  height: 17.5rem;

  .modal_row {
    display: flex;
    align-items: baseline;

    span {
      color: var(--primary-color);
      font-size: 1.75rem;
      font-weight: bold;
      margin-left: 1rem;
    }
  }

  .modal_body {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 1rem;
      font-weight: 600;
      margin: 0.5rem 0;
    }

    .desc {
      font-size: 0.8rem;
    }
  }

  button {
    font-size: 0.85rem;
    padding: 0.3rem 1rem;
    font-weight: 300;
    margin-top: 1.75rem;
    margin-left: auto;
  }
}

@media only screen and (max-width: 1008px) {
  .confirm_left {
    width: 90%;
    margin: 0 auto;
  }

  .confirm_status_text span {
    display: none;
  }

  .confirm_right {
    width: 90%;
    margin: 0 auto;
    padding: 0;

    .hotel_img {
      height: auto;
    }
  }

  .confirm_container {
    flex-direction: column;
  }

  .mobile_text {
    display: block !important;
    font-size: 1rem;
    color: #797777;
  }

  .right_row {
    flex-direction: column;

    .right_row_title {
      order: 2;
    }

    .right_row_nb {
      order: 1;
      display: flex;
      margin: 1rem 0;
    }

    .booking_nb_text {
      display: none;
    }

    .right_row_nb span {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 500px) {
  .cancel_cont {
    width: 20rem;
    height: 20rem;
  }

  .confirm_left {
    width: 100%;
  }

  .confirm_status_text h1 {
    font-size: 1.5rem;
  }

  .right_row_title,
  .right_row_nb {
    font-size: 1.5rem;
  }

  .confirm_container {
    padding-left: 0;
  }
}
