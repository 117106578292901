/** @format */

.gallery {
  display: flex;
  position: relative;
  justify-content: space-between;
  img {
    border-radius: 8px;
    image-rendering: -webkit-optimize-contrast;
  }
  .left {
    width: 32.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .left img {
    width: 100%;
    height: 10.75rem;
  }

  .right {
    width: 65%;
  }
  .right img {
    width: 100%;
    height: 22rem;
  }
}

@media only screen and (max-width: 1008px) {
  .gallery {
    flex-direction: column;

    .left {
      order: 2;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      img {
        width: 49%;
        height: 7.5rem;
      }
    }

    .right {
      order: 1;
      margin-bottom: 0.5rem;
      width: 100%;

      img {
        height: 12rem;
      }
    }
  }
}
